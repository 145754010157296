<template>
  <div>
    <PageHeader />
    <b-overlay :show="isUpdatingNotificationToken">
      <b-card class="mb-4">
          <form @submit.prevent="onSubmit">
            <b-form-group
              label="Line Token สำหรับแจ้งเตือน ฝากเงิน"
              label-for="line_deposit_token"
            >
              <b-input-group>
                <b-form-input
                  id="line_deposit_token"
                  v-model="form.line_deposit_token"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Line Token สำหรับแจ้งเตือน ถอนเงิน"
              label-for="line_withdraw_token"
            >
              <b-input-group>
                <b-form-input
                  id="line_withdraw_token"
                  v-model="form.line_withdraw_token"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            
            <div class="text-right">
              <b-overlay
                :show="isUpdatingNotificationToken"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'การแจ้งเตือน',
  },
  data() {
    return {
      form: {
        line_deposit_token: '',
        line_withdraw_token: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetchingNotificationToken: (state) =>
        state.setting.isFetchingNotificationToken,
      isUpdatingNotificationToken: (state) =>
        state.setting.isUpdatingNotificationToken,
    }),
    
     ...mapGetters(['notificationToken']),
  },
  watch: {},
  async created() {
     await this.fetchData()
     this.setData()
  },
  methods: {
    ...mapActions(['fetchNotificationToken', 'setNotificationToken']),
    setData() {
      if (this.notificationToken) {
        const data = JSON.parse(JSON.stringify(this.notificationToken))
        this.form = {
          line_deposit_token: data?.LINE_DEPOSIT || '',
          line_withdraw_token: data?.LINE_WITHDRAW || '',
        }
      } else {
        this.form = {
          line_deposit_token: '',
          line_withdraw_token: '',
        }
      }
    },
    async fetchData(){
      await this.fetchNotificationToken()
    },
    async onSubmit() {
      await this.setNotificationToken(this.form)
      this.fetchData()
    },
  },
}
</script>
